/* COLORS */
$color-primary_light: #191919;
$color-primary_dark: #fff;

$color-secondary: #a2a2ad;

$color-main: #ef5466;
$color-main-hover: #ca2a36;

$color-success: #4AD079;


/* BACKGROUND COLORS */
$background-primary_light: #fff;
$background-primary_dark: #121216;

$background-secondary_light: #fff;
$background-secondary_dark: #23232d;

$background-button-gray_light: #d1d1d6;
$background-button-gray_dark: #52525d;

$background-input_light: #F8F8F9;
$background-input_dark: #23232d;

$background-input-disabled_light: #FAFAFB;
$background-input-disabled_dark: #1C1C24;

$background-gradient_light: linear-gradient(90deg, rgba(160, 54, 56, 1) 0%, rgba(209, 87, 86, 1) 100%);
$background-gradient_dark: linear-gradient(90deg, rgba(34, 34, 34, 1) 0%, rgba(40, 45, 51, 1) 100%);

$background-genre-gradient_light: linear-gradient(to bottom right, #FF6868, #FF68DE);
$background-genre-gradient_dark: linear-gradient(to bottom right, #403F44, #1E1B32);


/* BUTTON HOVER BACKGROUND COLORS */
$background-on-hover_light: #eaeaea;
$background-on-hover_dark: #42424c;


/* LIBRARY HEADER PAGES */
$library-header-background_light: #F8F8F8;
$library-header-background_dark: #23232d;

$text-library-header-btn_light: #191919;
$text-library-header-btn_dark: #d1d1d6;

$background-library-header-btn_light: #d1d1d6;
$background-library-header-btn_dark: #52525d;

$text-active-library-header-btn_light: #000f80;
$text-active-library-header-btn_dark: #64a2ff;

$background-active-library-header-btn_light: #d1e1ff;
$background-active-library-header-btn_dark: #033464;


/* BORDERS */
$color-border-1px_light: 1px solid #eaeaea;
$color-border-1px_dark: 1px solid #32323d;

$color-border-2px_light: 2px solid #ffffff00;
$color-border-2px_dark: 2px solid #191919;

$color-border-btn_light: 2px solid #d1d1d6;
$color-border-btn_dark: 2px solid #52525d;


/* Shadows */
$popper-shadow_light: 0 4px 20px 0 #1919223d;
$popper-shadow_dark: 0 4px 20px 0 #0000003d;


/* MEDIA QUERY */
// website media query mobile/desktop
$media-breakpoint-desktop-mobile: 815px;

// Responsive down size
$media-breakpoint-down-sm: 573.98px;

// Responsive up size
$media-breakpoint-up-sm: 1175px;
$media-breakpoint-up-md: 1270px;


/* DATA THEME */
html[data-theme='light'] {
  --color-primary: #{$color-primary_light};
  --color-border-1px: #{$color-border-1px_light};
  --color-border-2px: #{$color-border-2px_light};
  --background-on-hover: #{$background-on-hover_light};
  --background-primary: #{$background-primary_light};
  --background-secondary: #{$background-secondary_light};
  --background-gradient: #{$background-gradient_light};
  --background-genre-gradient: #{$background-genre-gradient_light};
  --background-input: #{$background-input_light};
  --background-input-disabled: #{$background-input-disabled_light};
  --popper-shadow: #{$popper-shadow_light};
  --background-button-gray: #{$background-button-gray_light};
  --library-header-background: #{$library-header-background_light};
  --text-library-header-btn: #{$text-library-header-btn_light};
  --background-library-header-btn: #{$background-library-header-btn_light};
  --text-active-library-header-btn: #{$text-active-library-header-btn_light};
  --background-active-library-header-btn: #{$background-active-library-header-btn_light};
}

html[data-theme='dark'] {
  --color-primary: #{$color-primary_dark};
  --color-border-1px: #{$color-border-1px_dark};
  --color-border-2px: #{$color-border-2px_dark};
  --background-on-hover: #{$background-on-hover_dark};
  --background-primary: #{$background-primary_dark};
  --background-secondary: #{$background-secondary_dark};
  --background-gradient: #{$background-gradient_dark};
  --background-genre-gradient: #{$background-genre-gradient_dark};
  --background-input: #{$background-input_dark};
  --background-input-disabled: #{$background-input-disabled_dark};
  --popper-shadow: #{$popper-shadow_dark};
  --background-button-gray: #{$background-button-gray_dark};
  --library-header-background: #{$library-header-background_dark};
  --text-library-header-btn: #{$text-library-header-btn_dark};
  --background-library-header-btn: #{$background-library-header-btn_dark};
  --text-active-library-header-btn: #{$text-active-library-header-btn_dark};
  --background-active-library-header-btn: #{$background-active-library-header-btn_dark};
}