@mixin titleH1() {
  color: $font-primary-dark;
  font-weight: bold;
  font-size: 1.6em;

  @media (min-width: $media-breakpoint-up-sm) {
    font-size: 1.7em;
  }

  @media (min-width: $media-breakpoint-up-md) {
    font-size: 1.9em;
  }
}

@mixin anchorText() {
  color: $color-main;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: $color-main-hover;
  }
}

@mixin carousel-thumbnail-btn() {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-primary);
  background: none;
  border: 0;
  margin: 0;
  padding: 12px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.500);
  border-radius: 50%;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

  svg {
    height: 17px;
    width: 17px;
  }

  &:hover {
    background-color: #f4f4f4;
    border-radius: 100%;
  }
}

@mixin buttonPrimary($width: 220px) {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: $width;
  height: 40px;
  border: 0px;
  margin: 0px;
  padding: 10px 30px;
  cursor: pointer;
  border-radius: 20px;
  font-size: 15px;
  font-weight: 700;
  color: white;
  background-color: $color-main;
  transition: all 0.4s;

  span {
    text-transform: uppercase;
    padding: 0px 10px;
    padding-top: 2px;
  }

  &:hover {
    background-color: $color-main-hover;
  }
}

@mixin simple-btn-with-hover($height: 18px, $width: 18px, $padding: 0.4rem) {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-primary);
  background: none;
  border: 0;
  margin: 0;
  padding: $padding;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.4s;

  svg {
    height: $height;
    width: $width;
  }

  &:hover {
    background-color: var(--background-on-hover);
  }
}

@mixin btn-secondary() {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-primary);
  background: none;
  margin: 0;
  padding: 12px;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid var(--background-button-gray);
  transition: all 0.4s;

  svg {
    height: 16px;
    width: 16px;
  }

  &:hover {
    background-color: var(--background-on-hover);
  }
}