@import './sass/variables.scss';
@import './sass/mixins';
@import url('https://fonts.googleapis.com/css2?family=Anek+Gujarati:wght@600&family=Open+Sans:wght@300;400;700&display=swap');

:root {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 14px;
  color: $color-secondary;

  color-scheme: light dark;
  background-color: var(--background-primary);
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--background-on-hover);
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

body {
  margin: 0;
  user-select: none;
}

.swiper-carousel {
  width: 100%;
}

.swiper-carousel-button-next {
  display: flex;
  @include simple-btn-with-hover(18px, 18px, 10px);
}

.swiper-carousel-button-prev {
  display: flex;
  @include simple-btn-with-hover(18px, 18px, 10px);
}

.swiper-button-disabled {
  opacity: 0.25;
  cursor: not-allowed;
}

.display-none {
  display: none;
}

.swiper-slide {
  font-size: 18px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-wrapper {
  @media (max-width: $media-breakpoint-desktop-mobile) {
    .swiper-slide:first-child {
      margin-left: 18px !important;
    }
  }
}

.anticon-exclamation-circle,
.ant-btn-default {
  display: none;
}

.ant-modal-close {
  color: var(--color-primary) !important;
}

.ant-modal-confirm-btns {
  display: flex;
  justify-content: center;

  .ant-btn-primary {
    @include buttonPrimary;
    outline: none !important;
    height: 35px;
    margin-bottom: 28px !important;
  }
}

.ant-modal-confirm-content {
  width: 100%;
}

.ant-modal-content {
  background-color: var(--background-secondary) !important;
  border-radius: 8 !important;
  
  @media (max-width: 600px) {
    border-radius: 0 !important;
    padding: 20px 0 !important;
  }
}

.ant-popover-arrow {
  --antd-arrow-background-color: var(--background-secondary) !important;
}

.ant-popover-inner {
  background-color: var(--background-secondary) !important;
  padding: 0 !important;
}

@media (max-width: 600px) {
  .ant-modal {
    &:where(.css-dev-only-do-not-override-ed5zg0).ant-modal {
      max-width: 100%;
    }
  }

  [role=dialog] {
    height: 100vh;
    width: 100vw;
  }

  .ant-modal-content {
    height: 100dvh;
  }
}

.ant-message-notice-content {
  background: var(--background-on-hover) !important;
}

.ant-message {
  color: var(--color-primary);
}

@media (max-width: $media-breakpoint-desktop-mobile) {
  .ant-message-top {
    top: 0 !important;
  }
}